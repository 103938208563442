import { onPusher, fetchAsObservable } from "fetcher!sofe";
import { ReplaySubject } from "rxjs";
import { pluck } from "rxjs/operators";
import { globalUnreadNotifications } from "../notifications-ui";
import { handleError } from "src/error";
import { throttle } from "lodash";

/* We can't call fetcher until app-loader-ui has finished bootstrapping, but
 * we also need to call fetcher from different places depending on which
 * page is active (sometimes the primary navbar needs the message count
 * when notifications-ui child app isn't even active). So we use this
 * variable to ensure that we only set up the message count polling exactly
 * once.
 */
let isInitialized = false;

export const messageCounts = new ReplaySubject(1);

export function getMessageCounts() {
  return fetchAsObservable(`/message-counts`).pipe(pluck("message-counts"));
}

export function initialize() {
  if (!isInitialized) {
    onPusher("notification-center").subscribe(throttleUnreadMessageCounts, handleError);
    isInitialized = true;
    throttleUnreadMessageCounts();
  }
}

export const throttleUnreadMessageCounts = throttle(() => {
  getMessageCounts().subscribe((counts) => {
    messageCounts.next(counts);

    const allUnreadNotifications = counts.find((count) => count.id === "all");
    if (!allUnreadNotifications) {
      throw new Error(`Notifications api did not respond with an unread count for 'all' notification topic`);
    }

    globalUnreadNotifications.next(allUnreadNotifications.unreadCount);
  }, handleError);
}, 30000);
